import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

import colors from "../utils/colors"
import { outerStyles, innerStyles, mobileWidth } from "../utils/common-styles"
import dev from "../images/svg/dev.svg"

const Container = styled.header`
  ${outerStyles}
  background: ${colors.BLUE};
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mobileWidth}px) {
    align-items: center;
    height: 80px;
  }
`

const HomePageLink = styled(Link)`
  color: ${colors.WHITE};
  text-decoration: none;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding: 5px 0;
  @media (max-width: ${mobileWidth}px) {
    font-size: 18px;
  }
`

const DevIcon = styled.img`
  margin: -5px 5px -12px 0;
`

const Menu = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${mobileWidth}px) {
    display: none;
  }
`

const MobileMenuContainer = styled.div`
  display: none;
  @media (max-width: ${mobileWidth}px) {
    display: block;
    z-index: 1;
  }
`

const MenuMobile = styled.div`
  display: flex;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px 80px 30px 30px;
  background: ${colors.DARK_BLUE};
`

const MenuLink = styled(Link)`
  text-decoration: none;
  line-height: 30px;
  font-size: 14px;
  color: ${colors.WHITE};
  margin-left: 30px;
  ${({ selected }) =>
    selected &&
    css`
    color ${colors.GREEN};
  `}
  ${({ start }) =>
    !start &&
    css`
    @media (max-width: ${mobileWidth}px) {
      margin: 10px 0 0 20px;
    }
    &:hover {
      color ${colors.GREEN};
    }
  `}
  ${({ start }) =>
    start &&
    css`
      border: 1px solid ${colors.GREEN};
      padding: 4px 20px;
      border-radius: 10px;
      @media (max-width: ${mobileWidth}px) {
        margin: 10px 0 0 0;
      }
      &:hover {
        background: ${colors.GREEN};
      }
    `}
`

// TODO: get it from Contentful
const menuData = [
  {
    slug: "/about/",
    title: "Om oss",
    start: 0,
  },
  {
    slug: "/blog/",
    title: "Blogg",
    start: 0,
  },
  {
    slug: "/contact/",
    title: "Kontakta oss",
    start: 0,
  },
  {
    slug: "/quiz/",
    title: "Starta Quiz",
    start: 1,
  },
]

// TODO: make different styles for a selected page
const menuLinks = path =>
  menuData.map(({ slug, title, start }) => (
    <MenuLink key={slug} to={slug} start={start} selected={slug === path}>
      {title}
    </MenuLink>
  ))

const Header = ({ path }) => {
  const [menuOpened, setMenuOpened] = useState(0)

  const handleClick = () => {
    setMenuOpened(!menuOpened)
  }

  const className = `menu-icon${menuOpened ? " checked" : ""}`

  const menuMobileContainer = menuOpened ? (
    <MenuMobile>{menuLinks(path)}</MenuMobile>
  ) : null

  return (
    <Container>
      <Inner>
        <HomePageLink to="/">
          <DevIcon src={dev} alt="" />
          <span>Rekryterautvecklare.se</span>
        </HomePageLink>
        <Menu>{menuLinks(path)}</Menu>
        <MobileMenuContainer>
          <div className={className} onClick={handleClick} aria-hidden="true">
            <div className="navicon" />
          </div>
          {menuMobileContainer}
        </MobileMenuContainer>
      </Inner>
    </Container>
  )
}

Header.propTypes = {
  path: PropTypes.string,
}

export default Header
