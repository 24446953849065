import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import colors from "../utils/colors"
import {
  outerStyles,
  innerStyles,
  LinkToQuiz,
  Subtitle,
  mobileWidth,
} from "../utils/common-styles"
import dev from "../images/svg/dev.svg"

const ContainerFooter = styled.footer`
  ${outerStyles}
  background: ${colors.BLUE};
`

const ContainerNavigation = styled.section`
  ${outerStyles}
  background: ${colors.DARK_BLUE};
`

const ContainerStartQuiz = styled.section`
  ${outerStyles}
  background: ${colors.BLUE};
`

const Inner = styled.div`
  ${innerStyles}
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const InnerNavigation = styled.div`
  ${innerStyles}
  padding: 40px 20px;
`

const InnerStartQuiz = styled.div`
  ${innerStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
`

const HomePageLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  text-decoration: none;
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  padding: 5px 0;
`

const DevIcon = styled.img`
  margin: -11px 5px -11px 0;
  height: 30px;
`

const Copyright = styled.div`
  line-height: 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.GRAY_TEXT};
  margin-left: 30px;
  @media (max-width: ${mobileWidth}px) {
    margin-left: 0;
    text-transform: none;
  }
`

const Title = styled.h2`
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: ${colors.WHITE};
  margin-bottom: 60px;
`

const TitleStartQuiz = styled(Title)`
  margin-bottom: 20px;
`

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${mobileWidth}px) {
    justify-content: stretch;
  }
`

const NavGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  @media (max-width: ${mobileWidth}px) {
    margin-bottom: 30px;
  }
  &:last-child {
    margin-right: 0;
  }
`

const NavTitle = styled.h4`
  width: 180px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.WHITE};
  margin-bottom: 20px;
`

const NavLink = styled(Link)`
  color: ${colors.GREEN};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
`

const PrivacyLink = styled(Link)`
  line-height: 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: ${colors.GRAY_TEXT};
  margin-left: 30px;
  @media (max-width: ${mobileWidth}px) {
    margin-left: 0;
    text-transform: none;
  }
`

const Footer = props => (
  <>
    <ContainerStartQuiz>
      <InnerStartQuiz>
        <TitleStartQuiz>
          Hitta utvecklare med vårt quiz som tar mindre än 30 sek
        </TitleStartQuiz>
        <Subtitle>
          Vi ställer dig 10 snabba frågor för att bättre förstå dina behov och
          ger dig en personlig rekommendering
        </Subtitle>
        <LinkToQuiz to="/quiz/">Starta quizet</LinkToQuiz>
      </InnerStartQuiz>
    </ContainerStartQuiz>
    <ContainerNavigation>
      <InnerNavigation>
        <Title>Hitta och anlita utvecklare</Title>
        <NavLinks>
          <NavGroup>
          <NavTitle>Navigation</NavTitle>
            <NavLink to="/about/">Om oss</NavLink>
            <NavLink to="/blog/">Blogg</NavLink>
            <NavLink to="/contact/">Kontakta oss</NavLink>
          </NavGroup>
          <NavGroup>
            <NavTitle>Back-end utvecklare</NavTitle>
            <NavLink to="/symfony-utvecklare/">Symfony utvecklare</NavLink>
            <NavLink to="/laravel-utvecklare/">Laravel utvecklare</NavLink>
            <NavLink to="/node-utvecklare/">Node.js utvecklare</NavLink>
            <NavLink to="/php-utvecklare/">PHP utvecklare</NavLink>
            <NavLink to="/api-utvecklare/">API utvecklare</NavLink>
            <NavLink to="/cpp-utvecklare/">C++ utvecklare</NavLink>
            <NavLink to="/dotnet-utvecklare/">.NET utvecklare</NavLink>
            <NavLink to="/csharp-utvecklare/">C# utvecklare</NavLink>
            <NavLink to="/python-utvecklare/">Python utvecklare</NavLink>
          </NavGroup>
          <NavGroup>
            <NavTitle>Front-end utvecklare</NavTitle>
            <NavLink to="/vue-utvecklare/">Vue.js utvecklare</NavLink>
            <NavLink to="/react-utvecklare/">React.js utvecklare</NavLink>
            <NavLink to="/nuxt-utvecklare/">Nuxt utvecklare</NavLink>
            <NavLink to="/gatsby-js-utvecklare/">Gatsby.js utvecklare</NavLink>
            <NavLink to="/next-js-utvecklare/">Next.js utvecklare</NavLink>
            <NavLink to="/angular-utvecklare/">Angular utvecklare</NavLink>
            <NavLink to="/jquery-utvecklare/">jQuery utvecklare</NavLink>
          </NavGroup>
          <NavGroup>
            <NavTitle>Apputvecklare</NavTitle>
            <NavLink to="/flutter-utvecklare/">Flutter utvecklare</NavLink>
            <NavLink to="/react-native-utvecklare/">React Native utvecklare</NavLink>
            <NavLink to="/ionic-utvecklare/">Ionic utvecklare</NavLink>
            <NavLink to="/ios-utvecklare/">iOS utvecklare</NavLink>
            <NavLink to="/android-utvecklare/">Android utvecklare</NavLink>
            <NavLink to="/quasar-utvecklare/">Quasar utvecklare</NavLink>
          </NavGroup>
        </NavLinks>
      </InnerNavigation>
    </ContainerNavigation>
    <ContainerFooter>
      <Inner>
        <HomePageLink to="/">
          <DevIcon src={dev} alt="" />
          <span>Rekryterautvecklare.se</span>
        </HomePageLink>
        <Copyright>
          © {new Date().getFullYear()}, rekryterautvecklare.se
        </Copyright>
        <PrivacyLink to="/policy/">INTEGRITETSPOLICY</PrivacyLink>
      </Inner>
    </ContainerFooter>
  </>
)

export default Footer
